<!--
 * @Author: Penk
 * @LastEditors: Penk
 * @LastEditTime: 2021-12-21 11:58:49
 * @FilePath: \msedu-front-common-live\src\components\InteractionPanel\WhiteBoard.vue
-->

<template>
  <div id="whiteboard" class="whiteboard toggleDiv1" :class="{'whiteboard-fullscreen':isFull}" @click.stop.prevent="showVideo=!showVideo"
    >
111
    <div id="placeholderWarpper">
      <div class="placeholder"></div>
    </div>

    <!-- PC端DEMO -->
    <!-- {/* 控制条 */} -->
    <div class="bjy-playback-playslider-wrapper" :style="{ visibility: showVideo&&showControl ? 'visible' : 'hidden' }">
      <!-- {/* 播放 */} -->
      <span v-show="isPlaying" class="icon iconfont" style="color:#eee;" @click="videoPlay()">&#xe87c;</span>
      <span v-show="!isPlaying" class="icon iconfont" style="color:#eee;" @click="videoPlay()">&#xe693;</span>
      <div class="bjy-playback-playslider"></div>
      <!-- {/* 音量 */} -->
      <!-- <div :style="{position: 'relative'}">
        <span class="icon iconfont" style="color:#eee;" @click="isShowVolume=true;">&#xea11;</span>
        <div class="bjy-playback-volume-bar" :style="{ display: isShowVolume ? 'block' : 'none' }">
          <div class="bjy-playback-volume"></div>
        </div>
      </div> -->
      <!-- {/* 倍速 */} -->
      <div id="playback-rate-select" class="playback-rate-select" :style="{display:isPokoMini ?'block':'none'}">
        <select style="zIndex=999;background:#7f7f7f;color:#fff;border:none" @click.stop="changePlaybackRate($event)" v-model="playbackRate" @change.stop="changePlaybackRate($event)"
           >
            <option v-for="(item, index) in rateArr" :selected="item.value === playbackRate" :key="index" :value="item.value">
              {{item.label}}</option>
          </select>
        <!-- <Select value={this.state.playbackRate} onChange={this.changePlaybackRate} getPopupContainer={()=>
          document.getElementById('playback-rate-select')} dropdownclass="playback-rate-select-menu">
          {this.state.playbackRateArr.map((v, i) => {
          return <Option key={i} value={v.value} class="playback-rate-select-option">{v.label}</Option>
          })}
        </Select> -->
      </div>
      <!-- {/* 全屏 */} -->
      <img id="" src="@/assets/img/fullscreen.png" alt="" v-show="isShowFullScreenBtn" class="iconBtn"
        @click.stop.prevent="toggleWhiteboardFull()">
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: ['res', 'lastStudyTime', 'sectionInfo', 'videoData',"isPokoMini"],
    data() {
      return {
        showControl: true,
        showVideo: true,
        isPlaying: true,
        isShowVolume: true,
        networkState: true, //网络状态。默认true是正常状态

        isShowFullScreenBtn: true,
        isFull: false,
        rateArr: [{
            value: 0.5,
            label: 'x0.5'
          }, 
          {
            value: 1.25,
            label: 'x1.25'
          },
          {
            value: 1.0,
            label: 'x1.0'
          },
          {
            value: 1.5,
            label: 'x1.5'
          },
          {
            value: 2,
            label: 'x2.0'
          },
        ],
        playbackRate: 1,
        playerSlider: {},
      }
    },
    computed: {},
    watch: {
      res: {
        handler: function (newV, oldV) {},
        deep: true
      }
    },
    methods: {
      // 倍数改变触发
      changePlaybackRate(e) {
        console.log("e=========",e)
        e.stopPropagation()
        let player = document.getElementById('bjy-player-teacher');//视频播放器的倍速也要同步
        player.playbackRate = this.playbackRate;
        console.log("this.playbackRate",this.playbackRate,"player.playbackRate",player.playbackRate)
      },
      // 全屏 模式下打开一个元素 requestFullscreen
    getreqfullscreen () {
        var root = document.documentElement
        return root.requestFullscreen || root.webkitRequestFullscreen || root.mozRequestFullScreen || root.msRequestFullscreen
    },
    // 退出全屏
    getexitfullscreen(){
        return document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen
    },
    // 检查全屏
    getfullscreenelement(){
        return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
    },
    // 切换全屏按钮
    toggleWhiteboardFull() {
      let u = navigator.userAgent;
      let isIOS =  !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否为IOS系统
      if(isIOS){
          this.isFull ? this.exitFullScreen() : this.fullScreen()
      }else{
        let liveing = document.getElementById('whiteBoardBox') //
        console.log(2222,this.getfullscreenelement(),liveing)
        if(this.getfullscreenelement()){//已经全屏
            let exitFullscreen = this.getexitfullscreen()
            exitFullscreen.call(document)
        }else{//非全屏
            let fullscreene = this.getreqfullscreen()
            fullscreene.call(liveing)
        }
      }
      this.isFull = !this.isFull
    },

      // 全屏事件
      fullScreen() {
        let eventEmitter = BJY.eventEmitter;
        setTimeout(function () {
          eventEmitter.trigger(
            eventEmitter.DOC_FIT_CHANGE_TRIGGER, {
              value: 1,
            }
          );
        }, 50);
      },

      // 退出全屏事件
      exitFullScreen() {
        let eventEmitter = BJY.eventEmitter;
        setTimeout(function () {
          eventEmitter.trigger(
            eventEmitter.DOC_FIT_CHANGE_TRIGGER, {
              value: 2,
            }
          );
        }, 50);
      },

      // 控制条
      creatBjySlider(res) {
        let duration = res.videos[res.defaultDefinition][0].duration
        let currentTime = !!this.lastStudyTime ? this.lastStudyTime : 0;
        let video = this.player

        console.table([{
          name: "duration",
          value: duration
        }, {
          name: "currentTime",
          value: currentTime
        }]);

        this.playerSlider = BJY.PlayerSlider.create({
          element: $('.bjy-playback-playslider'),
          currentTime: currentTime, // 视频播放时间
          duration: duration, // 视频总时长
          canUseHotKey: true, // 是否支持快捷键
          onBeforeDrag: () => {
            // <-- 建议在此暂停视频播放 -->
            video.pause()
            this.isPlaying = false;
          },
          slideAnimation: (style) => {
            // <-- 可在此做滑动效果 -->
          },
          onAfterDrag: (time) => {
            // <-- 建议在此播放视频时间为 time -->
            video.currentTime = time
            video.play()
            this.isPlaying = true;
          },
          onBeforeSeek: (time) => {
            // <-- 建议在此暂停视频播放 -->
            video.pause()
            this.isPlaying = false;
          },
          onAfterSeek: (time) => {
            // <-- 建议在此播放视频时间为 time -->
            video.currentTime = time
            video.play()
            this.isPlaying = true;
          },
          onBeforeBackward: () => {
            // <-- 快退之前，建议在此暂停视频播放 -->
            video.pause()
            this.isPlaying = false;
          },
          onAfterBackward: (time) => {
            // time 是快退之后选中的时间 单位：s
            // <-- 快退之后，建议在此播放视频时间为 time -->
            video.currentTime = time
            video.play()
            this.isPlaying = true;
          },
          onBeforeForward: () => {
            // <-- 快进之前，建议在此暂停视频播放 -->
            video.pause()
            this.isPlaying = false;
          },
          onAfterForward: (time) => {
            // time 是快退之后选中的时间 单位：s
            // <-- 快进之后，建议在此播放视频时间为 time -->
            video.currentTime = time
            video.play()
            this.isPlaying = true;
          },
          onSpacePress: () => {
            // <-- 建议在此判断视频的播放状态 当前为暂停就开始播放 当前为播放就暂停 -->
            video.paused ? video.play() : video.pause()
            this.isPlaying = !video.paused;
          }
        })

        console.log("this.playerSlider:", this.playerSlider);
        // 必须监听视频的timeupdate事件，来告诉回放去根据视频时间更新数据
        video.addEventListener('timeupdate', () => {
          this.playerSlider && this.playerSlider.set('currentTime', video.currentTime)
        })

        setTimeout(() => {
          video.play();
        }, 10000);
      },
      // 音量
      creaBjyVolumeSlidert() {
        BJY.VolumeSlider.create({
          element: $('.bjy-playback-volume'),
          min: 0,
          max: 100,
          value: 30, // 默认音量
          onChange: (value) => {
            // <!-- 音量改变的时候触发，请在此处修改您的视频音量 -->
            this.player.volume = value / 100
          }
        })
        // hover效果
        $('.bjy-playback-playslider-wrapper').hover(() => {
          console.log('按兵不动')
        }, () => {
          this.isShowVolume = false;
        })
      },
      // 播放按钮
      videoPlay() {
        let video = this.player
        this.isPlaying = !this.isPlaying;
        this.$nextTick(() => {
          console.log("this.isPlaying:", this.isPlaying);
          video.paused ? video.play() : video.pause()
        })
      },
      // 音量
      showVolume() {
        this.isShowVolume = !this.isShowVolume;
      },
      //播放器
      creatBjyPlayer(res) {
        let me = this
        let video = this.player;
        let eventEmitter = BJY.eventEmitter;
        let store = BJY.store;

        let onPlaying = () => {
          console.log('onPlaying', new Date().getTime());
          BJY.playback.play();
          this.startTime = (new Date()).getTime();
          me.$emit('isPlaying')
        };
        let onPause = () => {
          console.log('onpause', new Date().getTime() - this.startTime);
          BJY.playback.pause();
          me.$emit('isPlaying')
        }
        let onSeeked = () => {
          console.log('onseeked', new Date().getTime() - this.startTime);
          BJY.playback.seek(video.currentTime);
          BJY.playback.play();
          console.log(video.currentTime);
          me.$emit('seeking')
        }
        let onEnd = () => {
          console.log('onend', new Date().getTime() - this.startTime);
          this.isPlaying = false;
          this.api_Connect(this.state.videoTime) //视频结束后调一次接口，针对几十秒的视频无法达到百分百进度的情况
          // 播放结束后自动暂停，不在这里写提交学习记录
          me.$emit('onEnd')
        }

        let onTimeUpdate = function () {
          BJY.playback.timeupdate(video.currentTime);
          me.$emit('timeUpdate', video.currentTime)
        };
        BJY.playback.start().then(() => {
          console.log('playback.start' + res)
          // <!-- 这里可以加入您需要的功能 -->
          let teacherVideos = res.videos;
          let videoWatermark = res.videoWatermark;
          // videoWatermark.url 在百家云后台设置的水印链接
          // videoWatermark.pos 在百家云后台设置的水印位置
          if (res.videos) {
            // 首先播默认分辨率
            let url = res.videos[res.defaultDefinition][0].url;
            url = url.replace('https:', '').replace('http:', '');
            if (url.indexOf(".ev1") > -1 && flvjs) {
              // 使用flvjs 播放加密格式视频
              flvPlayer = flvjs.createPlayer({
                type: 'ev1',
                url: url,
                cors: true
              }, {
                stashInitialSize: 1024,
              });
              flvPlayer.attachMediaElement(video);
              flvPlayer.load();
            } else {
              video.src = url;
            }

            let initialized = false;

            /* element.find('#player-screen').on('click', function () {
                video.play();
            }); */
            let lastStudyTime1 = !!this.lastStudyTime ? this.lastStudyTime : 0;

            video.oncanplay = function () {

              // 已启动过则不再执行
              if (initialized) {
                return;
              }
              // element.find('#player-screen').hide();
              initialized = true;

              video.currentTime = lastStudyTime1; //继续播放上次回放时间
            };

            video.addEventListener('play', function () {
              onPlaying();
            });
            video.addEventListener('pause', function () {
              onPause();
            });
            video.addEventListener('seeked', function () {
              onSeeked();
            });
            video.addEventListener('ended', function () {
              onEnd();
            });

            // 必须监听视频的timeupdate事件，来告诉回放去根据视频时间更新数据
            video.addEventListener('timeupdate', function () {
              onTimeUpdate();
              // console.log("播放？", this.paused);
            });
            video.addEventListener('play', () => {
              me.isPlaying = false;
              console.log("video playing...");
            });
            video.addEventListener('pause', () => {
              me.isPlaying = true;
              console.log("video paused...");
            });
            // 学习时间
            video.currentTime = !!this.lastStudyTime ? this.lastStudyTime : 0;
          }
          this.creatBjySlider(res) //控制条
        });
      }
    },

    created() {
    },
    mounted() {
      // 新版白板
      BJY.Whiteboard.create({
        element: $('#whiteboard .placeholder'),
        disablePptAnimate: true,
        fit: 1
      });

      this.player = document.getElementById('bjy-player-teacher');
      this.creatBjyPlayer(this.res) //播放器

      this.player.currentTime = !!this.lastStudyTime ? this.lastStudyTime : 0; //继续播放上次回放时间
      console.log("this.player",this.player)
      let _this = this
      if(this.isPokoMini){
          this.player.addEventListener('ratechange', function (e) {
            console.log("视频速度改变，白板的速度也要跟着改...",_this.player,_this.player.playbackRate);
            _this.playbackRate = _this.player.playbackRate
        })
      }
      setTimeout(() => {

        // BJY.playback.start();
      }, 1000);
      let u = navigator.userAgent;
      let isIOS =  !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否为IOS系统
      console.log("isIOS",isIOS)
      if(isIOS){
        this.rateArr =[{
            value: 0.5,
            label: 'x0.5'
          }, 
          {
            value: 1.25,
            label: 'x1.25'
          },
          {
            value: 1.0,
            label: 'x1.0'
          },
          {
            value: 1.5,
            label: 'x1.5'
          },
          {
            value: 2,
            label: 'x2.0'
          },
        ]
      }else{
          this.rateArr =[{
            value: 0.5,
            label: 'x0.5'
          },{
            value: 0.75,
            label: 'x0.75'
          },
          {
            value: 1,
            label: 'x1.0'
          },
          {
            value: 1.25,
            label: 'x1.25'
          },
          {
            value: 1.5,
            label: 'x1.5'
          },
          {
            value: 1.75,
            label: 'x1.75'
          },
          {
            value: 2,
            label: 'x2.0'
          },
        ]
      }
    },
    beforeDestroy() {}
  }
</script>

<style lang="scss" scoped>
  //@import url(); 引入公共css类

  // 控制条
  .bjy-playback-playslider-wrapper {
    transition: all 0.5s;
    // padding: 0 20px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    // width: 840px;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
    padding: 0 10px;
    box-sizing: border-box;

    .bjy-playback-playslider {
      width: 550px;
      // flex-grow: 1;
    }

    >* {
      margin-right: 10px;
    }

    >:last-child {
      margin-right: 0;
    }
  }

  .whiteboard-fullscreen {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 999 !important;
  }

  #whiteboard {
    min-height: 180px;
    width: 100%;
    position: relative;
    background: #000;

    /deep/ .bjy-whiteboard {
      background-color: rgb(31, 29, 29);
    }

    /deep/ .bjy-whiteboard-wrapper {
      width: 100%;
    }

    .btnBox {
      position: absolute;
      bottom: 5px;
      right: 5px;
      z-index: 1;
    }

    .iconBtn {
      width: 25px;
      height: 25px;
      color: #fff;
      border-radius: 5px;
    }
  }
</style>