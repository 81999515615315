// eslint-disable-next-line no-alert

import axios from 'axios'


export default {
	axiosData: function (method, url, data, isShowToast = true, hostType = '') {
    // let userInfo = localStorage.getItem('userInfo')
    // console.log('userInfo:',userInfo);
    let token = localStorage.getItem('userToken') || ''
    let host = localStorage.getItem('hostUrl') || ''
    let version = localStorage.getItem('version') || ''
    // let token = '17870e7fdc6d409390d0845af42a4990';
		// console.log(' === token host version===', token, host ,version )
		// console.log(' === host + url = ', host + url)
		axios.defaults.headers['authorization'] = token
		axios.defaults.headers['version'] = version
		return new Promise((resolve, reject)=>{
			axios({
				method,
				url: host + url,
				data: data,
			  }).then((res) => {
				let data = res.data;        
				if (data.status === 0 || data.status === '0' || data.status === 10 || data.code === 200 || !data.status){
					resolve(data)
				}else if(data.status === 1 && data.code == 'SEN001'){
					//token失效，跳转到登录页面，清除用户信息
          // message.error('token失效,请重新登录~');
					// resolve(data)
				}else if(data.status == 429){
					if (isShowToast) {
						// message.error('用户点击过快，请稍后再试~~');
					}
					resolve({code: -429})
				} else {
					if (isShowToast) {
						// message.error(data.msg);
					}
					resolve(data)
				}

			  }).catch((err) => {
				  if (isShowToast) {
					// message.error(err.msg)
				  }
				  resolve({code: -1000})
			  })   
		})
	},
	// promise处理的异步
	testTimeout: function (msg) {
		return new Promise(function (resolve, reject) {
			if (msg === 1) {
				setTimeout(function () {
					resolve(' sync succ ');
				}, 1000);
			} else {
				reject(' sync fail ');
			}
		});
	},

}



