import Vue from 'vue'
import './cube-ui'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')

// if (window && window.location && window.location.host && (window.location.host.indexOf('live.myunedu.com') === -1)) {
//   const script = document.createElement('script')
//   script.src = 'https://res.yy.com/libs/vconsole/vconsole.min.js'
//   document.body.appendChild(script)
// }
