<template>
  <div id="app">
    <Loading @loaded="loadEnd()" />
    <div v-if="loaded" id="main">

      <div class="media-panel">
        <!-- 聊天、问答、白板、公告等 -->
        <InteractionPanel :res="res" @timeUpdate="timeUpdate" :videoData="videoData" @onEnd="onEnd" :isPokoMini="pokoMini"
          @isPlaying="isPlaying('whiteboard')" @seeking="seeking" :lastStudyTime="lastStudyTime" :startTime="startTime"
          :sectionInfo="sectionInfo">

          <div id="" class="video-wrap toggleDiv2">
            <Player :videoData="videoData" @onEnd="onEnd" @isPlaying="isPlaying('video')" :lastStudyTime="lastStudyTime"
              @timeUpdate="timeUpdate" @seeking="seeking" ref="player" :isPokoMini="pokoMini" />
          </div>
        </InteractionPanel>

        <div class="cirBox" style="">
          <!-- 线路选择 -->
          <select style="zIndex=999;" v-if="lineList.length > 1" v-model="currentLine" name="line" id="line-control"
            title="线路">
            <option v-for="(item, index) in lineList" :selected="index === currentLine" :key="index" :value="index">
              {{index | lineFilter}}</option>
          </select>
        </div>
      </div>

      <!-- 弹出层，响应老师发起的测验，答题器等 -->
      <ModalPanel />
    </div>
  </div>
</template>

<script>
  import Loading from "./components/Loading";
  import Player from "./components/Player";
  import InteractionPanel from "./components/InteractionPanel";
  import ModalPanel from "./components/ModalPanel";
  import Notice from "./components/Notice";
  import App from "./common.js";
  import {
    Dialog
  } from 'vant';
  import moment from 'moment'

  var eventEmitter = BJY.eventEmitter;

  export default {
    name: "App",
    components: {
      Loading,
      Player,
      Notice,
      InteractionPanel,
      ModalPanel
    },
    beforeDestroy() {
      if (this.networkTimeId) {
        clearTimeout(this.networkTimeId)
      }
    },
    data() {
      return {
        res: null,
        startTime: '',
        loaded: false,
        videoData: null,
        currentLine: 0,
        lineList: [],
        sectionInfo: {}, //课程信息
        playing: false, //播放状态
        currentTime: '', //当前视频时间
        videoId: '',
        lastStudyTime: 0,
        networkState: true, //网络状态。默认true是正常状态
        networkTimeId: null, //断网定时器
        failVideo: [],
        failTotalTime: 0,
        productId: 0,
        subjectId: 0,
        teachPlanId: 0,
        channelId: 0,
        sectionId: 0,
        pokoMini: false,
        videoTime: 10,
      };
    },
    filters: {
      lineFilter(index) {
        return '线路' + (++index)
      }
    },
    watch: {
      currentLine: {
        deep: true,
        handler: function (val, oldVal) {
          this.videoData = this.lineList[val]
        }
      }
    },
    created() {
      // let url = "https://live.myunedu.com/commonlive?classid=19112191807307&session_id=202106303&token=fqdbq4dAqGRdENsGVW5J4hFQ55IAUOU-ovipNFvj36VT58C3DiNo86f2cfHJ4jTXBPh8xOJGM3QKp0fXMnVKLQ&userToken=9d36f5e87bc84a6ba68ccab635999068&videoId=522&hostUrl=https%3A%2F%2Fyk.myunedu.com&version=v2.8.7&lastStudyTime=325"
      let url = window.location.href
      this.startTime = (new Date()).getTime();
      var options = {
        token: "T4Ivx-DIWjgmvvoNNdcydzf3LcMMPzYEc4GNHnOv-QuVbTLv8dzJe_eqCFuUfaIw0nPLBgfsrQMKp0fXMnVKLQ",
        classId: "18070683424562",
        sessionId: 201807200,
        prefixName: ''
      };
      var params = this.getQuery(url);
      options.token = decodeURIComponent(params.token);
      options.classId = decodeURIComponent(params.classid);
      options.sessionId = decodeURIComponent(Number(params.session_id));
      this.videoId = Number(decodeURIComponent(params.videoId));
      this.lastStudyTime = Number(decodeURIComponent(params.lastStudyTime));
      // options.prefixName = params.prefixName;


      this.pokoMini = decodeURIComponent(params.pokoMini);
      if (this.pokoMini == 'undefined') {
        this.pokoMini = false
      }
      console.log('pokoMini:', this.pokoMini)
      if (this.pokoMini) {
        this.productId = Number(decodeURIComponent(params.productId));
        this.subjectId = Number(decodeURIComponent(params.subjectId));
        this.teachPlanId = Number(decodeURIComponent(params.teachPlanId));
        this.channelId = Number(decodeURIComponent(params.classid));
        this.sectionId = Number(decodeURIComponent(params.sectionId));
      }
      let userToken = decodeURIComponent(params.userToken);
      let hostUrl = decodeURIComponent(params.hostUrl);
      let version = decodeURIComponent(params.version);
      localStorage.setItem('userToken', userToken);
      localStorage.setItem('hostUrl', hostUrl);
      localStorage.setItem('version', version);
      // options.token = '5MDWoJpRF1ZdENsGVW5J4hFQ55IAUOU-ovipNFvj36URzYO_WOBXHq7czUAG5PUgPksWalcCxLkKp0fXMnVKLQ';
      // options.classId = '19112191807307';
      // options.sessionId = '202106212';
      // options.prefixName = '';

      if (location.href.includes('debug=1')) {
        // <!-- 这里给一个测试入口：输入回放的url即可测试对应的回放 -->
        var testUrl = prompt("输入回放url以测试");

        // 有回放链接用回放链接，没有回放链接用我们的测试用例
        if (testUrl) {
          var params = this.getQuery(testUrl);
          options.token = params.token;
          options.classId = params.classid;
          options.sessionId = Number(params.session_id);
          options.prefixName = params.prefixName;
        }
        console.log(options);
      }

      // 初始化房间
      BJY.playback
        .init({
          env: "production",
          privateDomainPrefix: 'b53939712',
          token: options.token,
          class: {
            id: options.classId,
              sessionId: options.sessionId
          },
          useEncrypt: true,
          user: {
            number: "13147056",
            avatar: "http://static.sunlands.com/newUserImagePath/13147056/40_40/13147056.jpg",
            name: "xxx",
            type: 0
          }
        })
        .then(data => {
          this.loaded = true;
          this.res = data;
          let quality = data.defaultDefinition
          this.videoData = data.videos[quality][this.currentLine];
          this.lineList = data.videos[quality]
          console.log("lineList==========",this.lineList)
        });
    },
    mounted() {
      let failVideo = localStorage.getItem('failVideo') || '[]'
      this.failVideo = JSON.parse(failVideo)
      let i = 0;
      let getRes = {};
      console.log(JSON.parse(failVideo));
      if (this.pokoMini) {
        return
      }
      JSON.parse(failVideo).forEach(async (item, index) => {
        console.log('qq');
        if (this.videoId === item.videoId) {
          console.log('ww');
          i = index
          getRes = await App.axiosData('post', '/student/web/userstudyrecord/addVideoTime', {
            videoId: this.videoId,
            studyTime: item.time,
            uploadType: 2,
            lastStudyTime: parseInt(this.currentTime),
            localCreateTime: new Date().getTime(),
            appType: 5,
          }, false)
          if (getRes.code && getRes.code === 'REQ001') {
            console.log(this.failVideo.splice(i, 1));
            localStorage.setItem('failVideo', JSON.stringify(this.failVideo.splice(i, 1)))
            
          this.loaded = true;
          }
        }
      })
    },
    methods: {
      loadEnd() {
        $("#main").css("top", 0);
      },
      getQuery(url) {
        console.log("url============",url)
        var query = url.split("?")[1];
        // 获取自定义域名
        var prefixName = url.split('.')[0].split('//')[1]

        var list = query.split("&");
        var params = {
          prefixName: prefixName
        };
        list.forEach(item => {
          var pair = item.split("=");
          params[pair[0]] = pair[1];
        });
        return params;
      },
      async saveStudyRecord() {
        if (this.pokoMini) {
          return
        }
        let startTime = this.startTime;
        let nowTime = (new Date()).getTime();
        let time = nowTime - startTime
        if (time >= 10000 && startTime > 0) {
          const getRes = await App.axiosData('post', '/student/web/userstudyrecord/addVideoProgress', {
            videoId: this.videoId,
            lastStudyTime: parseInt(this.currentTime),
          })
          if (getRes.data !== null) {}
        }
      },
      // 获取基本配置
      async api_getConfig() {
        console.log("this.pokoMini:",this.pokoMini,this.videoTime);
        if (!this.pokoMini) {
          const getRes = await App.axiosData('post', '/student/web/userstudyrecord/videoTimeConfig', {})
          if (getRes.code === 'REQ001' && getRes.data && getRes.data.videoTime) {
            this.videoTime = getRes.data.videoTime
          } else {
            // message.error(getRes.msg);
          }
        }
        
        this.startTimer(this.videoTime)
      },
      // 心跳统计时长
      async api_Connect(e) {
        let uploadType = 1 //上传类型，1:第一次心跳上传,2:补充上传（包括本地缓存、第一次失败等情况）
        let i = -1;
        let studyTime = e
        this.failVideo.forEach((item, index) => {
          if (this.videoId === item.videoId) {
            i = index
            studyTime = item.time
          }
        })
        i === -1 ? uploadType = 1 : uploadType = 2
        if (uploadType === 2) { //如果是补充上传加多10s
          studyTime += 10
        }
        let getRes = false;
        let nowTime = (new Date()).getTime()

        try {
          let url
          let param
          if (this.pokoMini) {
            url = '/study/wap/studyrecrod/addStudyRecord'
            let time = nowTime - this.startTime
            param = {
              sectionType: '4',
              channelId: this.channelId,
              sectionId: this.sectionId,
              productId: this.productId,
              subjectId: this.subjectId,
              teachPlanId: this.teachPlanId,
              studyTime: Math.round(time / 1000),
              lastStudyTime: parseInt(this.currentTime), // parseInapi_getConfigt
              beginStudyDate: moment(this.startTime).format('YYYY-MM-DD hh:mm:ss'),
              endStudyDate: moment(nowTime).format('YYYY-MM-DD hh:mm:ss'),
            }
          } else {
            url = '/student/web/userstudyrecord/addVideoTime'
            param = {
              videoId: this.videoId,
              studyTime: studyTime,
              uploadType: uploadType,
              lastStudyTime: parseInt(this.currentTime),
              localCreateTime: new Date().getTime(),
              appType: 5
            }
          }
          console.log('心跳上传', param)
          getRes = await App.axiosData('post', url, param)
        } catch (e) {
          console.log(' ==== e === ', e)
        }
        if (!getRes || (getRes && getRes.code !== 'REQ001')) {
          //上传心跳失败，保存失败时长
          console.log('心跳上传失败', getRes)
          if (this.playing) { //如果不是暂停并且结束
            this.failTotalTime += e
            console.log(this.failTotalTime);
          }
          let i = -1;
          this.failVideo.forEach((item, index) => {
            if (this.videoId === item.videoId) {
              i = index
              item.time = this.failTotalTime
            }
          })
          if (i === -1) {
            this.failVideo.push({
              videoId: this.videoId,
              time: this.failTotalTime
            })
          }
          localStorage.setItem('failVideo', JSON.stringify(this.failVideo))
          if (this.networkState) {
            // this.$refs.player&&this.$refs.player.onPause();
            document.getElementById("bjy-player-teacher").pause();
            this.networkState = false;
            Dialog.alert({
              title: '提示',
              message: '检测到您的网络质量差，为了更好的播放体验正在尝试缓存视频，播放已自动暂停~请点击下方“确定”按钮关闭此弹窗',
            }).then(() => {
              // on close
              this.networkTimeId = setTimeout(() => {
                this.networkState = true;
              }, 5 * 60 * 1000)
            });
          }
        } else {
          // 修改poko BUG 时间问题
          if(this.pokoMini) {
            this.startTime = nowTime;
          }

          console.log('心跳上传成功')
          this.failTotalTime = 0
          this.failVideo.forEach(async (item, index) => {
            if (this.videoId === item.videoId) {
              console.log(this.failVideo.splice(index, 1));
              localStorage.setItem('failVideo', JSON.stringify(this.failVideo.splice(index, 1)))
            }
          })
          if (this.networkTimeId) {
            clearTimeout(this.networkTimeId);
            this.networkTimeId = null;
            this.networkState = true;
          }
        }

      },
      // 开始定时器
      startTimer(e) {
        let self = this
        this.stopTimer()
        /*
        if (this.isLiving) { //是否直播
          this.checkLiveTimer = window.setInterval(function () {
            self.saveStudyRecord()
            self.api_Connect(e)
          }, e * 1000)
        } else {
          this.checkVideoTimer = window.setInterval(function () {
            self.saveStudyRecord()
            self.api_Connect(e)
          }, e * 1000)
        }
        */

        this.checkVideoTimer = window.setInterval(function () {
          try {
            if (!window.showVideo) {
              window.showVideo = document.getElementById('bjy-player-teacher');
            }
            if (window.showVideo && !window.showVideo.paused) {
              self.saveStudyRecord()
              self.api_Connect(e)
            }
          } catch (e) {}

        }, e * 1000)

      },
      // 停止定时器
      stopTimer() {
        if (this.checkLivingTimer) {
          window.clearInterval(this.checkLivingTimer)
          this.checkLivingTimer = null
        }
        if (this.checkVideoTimer) {
          window.clearInterval(this.checkVideoTimer)
          this.checkVideoTimer = null
        }
      },
      //播放状态
      isPlaying(obj) {
        console.log("isPlaying:",obj);
        if (window.showVideo) {
          this.playing = !window.showVideo.paused
          // console.log(' === this.playing == ', this.playing);
          if (!window.showVideo.paused) { // this.playing
            console.log(this.playing);
            this.startTime = (new Date()).getTime()
            this.api_getConfig()
          } else {
            console.log(this.playing);
            this.stopTimer()
          }
        }
      },
      onEnd() {
        console.log("onEnd");
        // console.log(' == on end == ', this.videoTime)
        this.api_Connect(this.videoTime)
      },
      //拖动进度条
      seeking() {
        // console.log("seeking");
        this.saveStudyRecord()
      },
      timeUpdate(currentTime) {
        // console.log(`timeUpdate(${currentTime})`);
        this.currentTime = currentTime
      }
    },
  };
</script>

<style lang="scss">
  // @import "./assets/iconfont/iconfont.css";
  @import "./assets/iconfont1/iconfont.css";

  #app {
    height: 100%;
    width: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #main {
      position: absolute;
      width: 100%;
      height: 100%;
      top: -10000px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .video-wrap {
        width: 50%;
        height: auto;
        position: relative;
      }

      .media-panel {
        flex-grow: 1;
        width: 100%;
        position: relative;
        overflow: auto;

        #line-control {
          // position: absolute;
          // top: 80px;
          background: #fff;
          z-index: 1;
          right: 10px;
        }

        .cirBox {
          position: absolute;
          text-align: right;
          top: 260px;
          right: 75px;
          width: 100%;
        }
      }
    }
  }
</style>