<!--
 * @Author: Penk
 * @LastEditors: Penk
 * @LastEditTime: 2021-12-20 11:53:05
 * @FilePath: \msedu-front-common-live\src\components\InteractionPanel\index.vue
-->
<template>
    <div class="interaction"  id="whiteBoardBox">
        <!-- <span class="toggleBtn" @click="toggleDiv">切换</span> -->
        <WhiteBoard ref="WhiteBoard" @timeUpdate="timeUpdate" :lastStudyTime="lastStudyTime" @onEnd="onEnd"
            @isPlaying="isPlaying" @seeking="seeking" v-bind="$attrs" v-on="$listeners"  />
        <slot></slot>
        <span class="toggleBtn" @click="toggleDiv">切换画面</span>
        <div class="tab-header">
            <span class="tab-message" :class="{ active: getCurrentTab('message') }" @click="setTab('message')">聊天</span>
            <span class="tab-question" :class="{ active: getCurrentTab('question') }"
                @click="setTab('question')">问答</span>
            <!-- <span class="tab-board" :class="{ active: getCurrentTab('board') }" @click="setTab('board')">白板</span> -->
        </div>
        <div class="notice">
            <Notice />
        </div>
        <div class="tab-body">
            <MessageList :class="{'visible': getCurrentTab('message')}" />
            <QuestionAnswer :class="{'visible': getCurrentTab('question')}" />
        </div>
    </div>
</template>

<script>
    import MessageList from "./MessageList";
    import QuestionAnswer from "./QuestionAnswer";
    import WhiteBoard from "./WhiteBoard";
    import Notice from "../Notice";

    // const eventEmitter = BJY.eventEmitter

    export default {
        props: ['lastStudyTime', 'videoData'],
        components: {
            MessageList,
            QuestionAnswer,
            Notice,
            WhiteBoard
        },
        data() {
            return {
                currentTab: "message"
            };
        },
        computed: {},
        watch: {},
        methods: {
            toggleDiv() {
                let div1 = document.getElementsByClassName("toggleDiv1")[0];
                let div2 = document.getElementsByClassName("toggleDiv2")[0];
                this.exchange(div1, div2);
            },
            exchange(el1, el2) {
                var ep1 = el1.parentNode,
                    ep2 = el2.parentNode,
                    index1 = Array.prototype.indexOf.call(ep1.children, el1),
                    index2 = Array.prototype.indexOf.call(ep2.children, el2);
                ep2.insertBefore(el1, ep2.children[index2]);
                ep1.insertBefore(el2, ep1.children[index1]);
                el1.style.width = "50%";
                el2.style.width = "100%";

                el1.classList.add("toggleDiv2");
                el1.classList.remove("toggleDiv1");
                el2.classList.add("toggleDiv1");
                el2.classList.remove("toggleDiv2");

                this.$nextTick(() => {
                    // 视频是否在第一个DIV
                    if ($(".toggleDiv1").find("#bjy-player-teacher").length > 0) {
                        $("#bjy-player-teacher").attr("controls", true);
                    } else {
                        $("#bjy-player-teacher").attr("controls", false);
                    }

                    // 画布是不是在第一个DIV WhiteBoard
                    if ($(".whiteboard").hasClass("toggleDiv1")) {
                        this.$refs.WhiteBoard.showControl = true;
                    } else {
                        this.$refs.WhiteBoard.showControl = false;
                    }

                    let eventEmitter = BJY.eventEmitter;
                    setTimeout(function () {
                        eventEmitter.trigger(
                            eventEmitter.DOC_FIT_CHANGE_TRIGGER, {
                                value: 1,
                            }
                        );
                    }, 50);
                })
            },
            getCurrentTab(tab) {
                return this.currentTab === tab;
            },
            setTab(tab) {
                this.currentTab = tab;
            },
            timeUpdate(currentTime) {
                this.$emit('timeUpdate', currentTime)
            },
            isPlaying() {
                this.$emit('isPlaying')
            },
            onEnd() {
                this.$emit('onEnd')
            },
            seeking() {
                this.$emit('seeking')
            }
        },
        created() {},
        mounted() {},
        beforeDestroy() {}
    };
</script>

<style lang="scss" scoped>
.toggleBtn{
    position: absolute;
    right: 2px;
    top: 261px;
    color: #000;
    font-size: 15px;
    z-index: 99;
    width: 66px;
    height: 20px;
    overflow: hidden;
    display: inline-block;
}

    /deep/.toggleDiv1 {
        height: 180px;
        min-height: 180px;
        margin-bottom: 2px;
    }

    /deep/.toggleDiv2 {
        height: 100px !important;
        min-height: 100px !important;
        margin-bottom: 2px;
    }

    //@import url(); 引入公共css类
    .interaction {
        position: absolute;
        top: 0px;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: space-between;

        .tab-header {
            width: 100%;
            height: 36px;
            font-size: 16px;
            background: #fff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            &>* {
                padding: 6px;
                width: 50%;
                text-align: center;
                cursor: pointer;
                box-sizing: border-box;
                display: inline-block;
            }

            .active {
                border-bottom: 2px solid #1795ff;
                color: #1795ff;
            }
        }

        .tab-body {
            // top: 36px;
            width: 100%;
            height: 100%;
            flex-grow: 1;
            position: relative;
            overflow: hidden;

            .visible {
                visibility: visible !important;
            }
        }
    }
</style>