<template>
    <!-- muted 避免无法自动播放  行内播放属性IOS微信浏览器支持小窗内播放
        IOS没有加playsinline 这个属性的话，页面播放的时候只能竖屏展示viedo内容，不能展示白板内容，除非暂停状态
    
    
    -->
    <video id="bjy-player-teacher" x-webkit-airplay="allow" :playsinline="isIOS ? true : false"  :x5-playsinline="isIOS ? true : false"   :webkit-playsinline="isIOS ? true : false"
         controlsList='nodownload noremoteplayback'></video>
</template>

<script>
    export default {
        props: ["videoData", "lastStudyTime","isPokoMini"],
        components: {},
        data() {
            return {
                tip: "",
                started: false,
                video: null,
                isIOS:false,
            };
        },
        computed: {},
        watch: {
            videoData: {
                deep: true,
                handler: function (val, oldVal) {
                    this.attachVideo(val)
                }
            }
        },
        methods: {
            onPlaying() {
                BJY.playback.play();
                this.$emit('isPlaying')
            },
            onPause() {
                console.log("onpause");
                BJY.playback.pause();
                this.$emit('isPlaying')
            },
            onEnd() {
                console.log("onend");
                this.$emit('onEnd')
            },
            onSeeked() {
                console.log("onseeked");
                BJY.playback.seek(this.video.currentTime);
                BJY.playback.play();
                this.$emit('timeUpdate', this.video.currentTime)
                this.$emit('seeking')
            },
            onTimeUpdate() {
                BJY.playback.timeupdate(this.video.currentTime);
                this.$emit('timeUpdate', this.video.currentTime)
            },
            attachVideo(data) {
                // 首先播默认分辨率
                console.log("视频数据=============",data)
                var url = data.url;
                url = url.replace("https:", "").replace("http:", "");
                if (url.indexOf(".ev1") > -1 && flvjs) {
                    // 使用flvjs 播放加密格式视频
                    let flvPlayer = flvjs.createPlayer({
                        type: "ev1",
                        url: url,
                        cors: true
                    }, {
                        stashInitialSize: 1024
                    });
                    flvPlayer.attachMediaElement(this.video);
                    flvPlayer.load();
                } else {
                    this.video.src = url;
                }
            }
        },
        mounted() {
            let u = navigator.userAgent;
            this.isIOS =  !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否为IOS系统
            window.showVideo = document.getElementById("bjy-player-teacher")
            if(!this.isPokoMini){
                 window.showVideo.addEventListener('ratechange', function (e) {
                    console.log("速度不能改变，否则不算看视频完毕...");
                    window.showVideo.playbackRate = 1;
                })
            }
           
            /*
            let video = (this.video = document.getElementById( "bjy-player-teacher"));
            let data = this.videoData;
            let me = this;
            // video.currentTime = this.lastStudyTime; //继续播放上次回放时间
            BJY.playback.start();
            if (data) {
                this.attachVideo(data)

                video.addEventListener("canplay", function() {
                    console.log(' ===== canplay window.setPlayCurTime ', window.setPlayCurTime)
                    if (!window.setPlayCurTime) {
                        window.setPlayCurTime = true
                        video.currentTime = me.lastStudyTime; //继续播放上次回放时间
                        console.log(' ===== video currentTime ', video.currentTime)
                    }
                });

                video.addEventListener("play", function() {
                    me.onPlaying();
                });

                video.addEventListener("pause", function() {
                    me.onPause();
                });

                video.addEventListener("ended", function() {
                    me.onEnd();
                });

                video.addEventListener("seeked", function() {
                    me.onSeeked();
                });

                // 必须监听视频的timeupdate事件，来告诉回放去根据视频时间更新数据
                video.addEventListener("timeupdate", function() {
                    me.onTimeUpdate();
                });
            }
                */
        },
        beforeDestroy() {}
    };
</script>

<style lang="scss" scoped>
    //@import url(); 引入公共css类

    #bjy-player-teacher {
        // height: 240px;
        text-align: left;
        width: 100%;
        height: 100%;
        background: #454545;
        position: relative;
    }

    /*video默认aduio音量按钮*/
    video::-webkit-media-controls-mute-button {
        display: none !important;
    }

    /*video默认setting按钮*/
    video::-internal-media-controls-overflow-button {
        display: none !important;
    }

    /*腾讯云点播禁用firefox全屏、设置按钮*/
    .trump-button[sub-component="setting"] {
        display: none !important;
    }
</style>